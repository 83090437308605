import Siteroutes from "./siteroutes";
import AuthRoutes from "./authRoutes";

const Routes = [
    ...Siteroutes
]
const Auth = [
    ...AuthRoutes
]

 
export {Routes,Auth}

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'react-phone-input-2/lib/style.css'
import {ThemeProviderWrapper} from "./context/themeContext";
import {AuthProviderWrapper} from "./context/authContext";
import 'react-toastify/dist/ReactToastify.min.css';
import {ToastContainer} from "react-toastify";
import "animate.css/animate.min.css";
import 'intl-tel-input/build/css/intlTelInput.css';
import 'react-quill/dist/quill.snow.css'
import {createRoot} from "react-dom/client";
import UserService from "./services/UserService";
import {PopUpWrapper} from "./context/popupContext";
import PopupAlert from "./components/elements/popup";

const keycloakAuthenticationFlowEnabled = process.env.REACT_APP_AUTHENTICATION_FLOW === "keycloak";
if (keycloakAuthenticationFlowEnabled) {
    const renderApp = () => createRoot(document.getElementById("root")).render(
        <PopUpWrapper>
            <AuthProviderWrapper>
                <ThemeProviderWrapper>
                    <BrowserRouter>
                        <PopupAlert />
                        <App/>
                    </BrowserRouter>
                    <ToastContainer/>
                </ThemeProviderWrapper>
            </AuthProviderWrapper>
        </PopUpWrapper>
    );
    UserService.enableKeycloak()
    UserService.initKeycloak(renderApp);
} else {
    ReactDOM.render(
        <PopUpWrapper>
            <AuthProviderWrapper>
                <ThemeProviderWrapper>
                    <BrowserRouter>
                        <PopupAlert />
                        <App/>
                    </BrowserRouter>
                    <ToastContainer/>
                </ThemeProviderWrapper>
            </AuthProviderWrapper>
        </PopUpWrapper>
        ,
        document.getElementById('root')
    )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

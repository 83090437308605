import React from "react";
import "../../assets/style/errorStyle.css"
import {Link} from "react-router-dom";
import animmation4040 from "../../assets/lottie/4042.json";
import Lottie from "lottie-react";

const Erreur404 = () =>{
    return(
        <div className="container-error">
            <div className="container">
                <div className="col-12  container d-flex align-items-center justify-content-center">
                    <Lottie animationData={animmation4040} />
                </div>
            </div>
            <div className="container d-flex align-items-center justify-content-center">
                <h3 className="error-titre">Page not found</h3>
            </div>
            <div className="container d-flex align-items-center justify-content-center">
                <Link to="/">Go back </Link>
            </div>
        </div>
    )
}
export default Erreur404;
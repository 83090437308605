import React, {createContext, useContext, useEffect, useState} from "react";
import api from "../services/axios";
import UserService from "../services/UserService";
import {PopupContext} from "./popupContext";

const Context = createContext();

const AuthProviderWrapper = ({children}) => {
    let initialToken = undefined
    const [isAuth, setIsAuth] = useState(initialToken)
    const [isLoadAuth, setIsLoadAuth] = useState(false)
    const [userData, setUserData] = useState(null)
    const [isLoadAuthData, setIsLoadAuthData] = useState(false)
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);

    const {showInformationPopUp, resolveAndPopUpApiCallError} = useContext(PopupContext)

    useEffect(() => {
        if (UserService.isLoggedIn()) {
            setIsAuth(UserService.getToken())
            setIsLoadAuth(UserService.getToken)
        } else {
            setIsOpenAuthModal(true)
        }
    }, [])


    const Logout = () => {
        let redirectUri = window.location.origin
        UserService.doLogout({redirectUri: redirectUri}).then(() => {
            window.localStorage.removeItem("ad_auth_token")
            setIsAuth(null)
            setUserData(null)
            initialToken = undefined
            window.location.href = "/";
            showInformationPopUp("Logged out")
        })
    }


    const openAuthModal = () => {
        if (UserService.isLoggedIn()) {
            setIsLoadAuth(UserService.getToken)
        } else {
            setIsOpenAuthModal(true)
        }
    }

    const closeAuthModal = () => {
        setIsOpenAuthModal(false)
    }


    const loginAction = async () => {
        await UserService.doLogin({redirectUri: window.location.origin})
        if (UserService.isLoggedIn()) {
            setIsAuth(UserService.getToken())
            closeAuthModal()
        }
    }
    const getUserData = async () => {
        setIsLoadAuthData(true)
        await api.get(`admin/admins/my-profile`)
            .then((response) => {
                if (response.data) {
                    setUserData(response?.data)
                }
            }).catch((error) => {
                //console.log("error data", error)
                resolveAndPopUpApiCallError(error);
            }).finally(() => {
                setIsLoadAuthData(false)
            })
    }
    return (
        <Context.Provider value={{
            isAuth,
            setIsAuth,
            isOpenAuthModal,
            openAuthModal,
            closeAuthModal,
            isLoadAuth,
            loginAction,
            isLoadAuthData,
            setIsLoadAuthData,
            userData,
            Logout,
            getUserData
        }}>
            {children}
        </Context.Provider>
    )

}
export {AuthProviderWrapper, Context as AuthContext}

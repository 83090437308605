import axios from "axios";
import UserService from "./UserService";

const api = axios.create({
    baseURL : process.env.REACT_APP_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    },
    mode: "no-cors"
})

api.interceptors.request.use(
    function (req) {
        if (UserService.isKeycloakEnabled() && UserService.isLoggedIn()) {
            const cb = () => {
                req.headers.Authorization = `Bearer ${UserService.getToken()}`;
                req.headers.set("X-Oauth-Flow","kc")
                return Promise.resolve(req);
            };
            return UserService.updateToken(cb);
        }else {
            return req;
        }
    },
    (err) => {
        return Promise.reject(err);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Handle the 401 response here, e.g., redirect to the login page
            if(UserService.isKeycloakEnabled()){
                return UserService.doLogin({redirectUri: window.location.origin})
                    .then(() => {
                        if(UserService.isLoggedIn()){
                            console.log("login success")
                        }
                    })
            }
        }
        return Promise.reject(error);
    }
);


export default api;

import React from "react";
import {ProgressBar} from "react-loader-spinner";

export const SiteLoader=()=>{
    return(
        <div className="container-loader">
            <ProgressBar
                height="80"
                width="100"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor = '#FF8E2B'
                barColor = '#51E5FF'
            />
        </div>
    )
}

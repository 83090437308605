import React from "react"
import Routers from "./routeur/Routers";
import * as moment from 'moment';
import 'moment/locale/en-gb';

moment.locale('en-us');
function App  () {

    return (
        <Routers/>
    )
}
export default App;


import React, {useContext, useEffect} from 'react'
import { PopupContext } from "../../../context/popupContext";
import {toast} from "react-toastify";

const PopupAlert  = () => {

  const {popUps, closePopUp}=useContext(PopupContext)
  useEffect(()=>{
    popUps.information?.map(info => {
          toast.success(info.message, {
            position: "top-right",
            autoClose: info.delay * 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => closePopUp(info) // ensure pop up was closed
          });
          closePopUp(info); // close popup immediately after passing it to react-toaster
        }
    );

    popUps.errors?.map(err => {
          toast.error(err.message, {
            position: "top-right",
            autoClose: err.delay * 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => closePopUp(err) // ensure pop up was closed
          })
          closePopUp(err) // close popup immediately after passing it to react-toaster
        }
    );

  },[popUps])


  return (
    <div/>
  )
}

export default PopupAlert


import {lazy} from 'react'

const Siteroutes = [
    {
        ind:{
            index:true,
        },
        component: lazy(() => import('../../pages/site/home/')),
        exact: true
    },

    {
        path: '/home',
        component: lazy(() => import('../../pages/site/home/')),
        ind:{},
        exact: true,

    },

    {
        path: '/candidates',
        component: lazy(() => import('../../pages/site/candidats/')),
        ind:{},
        exact: true,

    },
    {
        path: '/management-technology',
        component: lazy(() => import('../../pages/site/managementTechnology/')),
        ind:{},
        exact: true,

    },
    {
        path: '/management-language',
        component: lazy(() => import('../../pages/site/managementLanguage/')),
        ind:{},
        exact: true,

    },
    {
        path: '/management-job-role',
        component: lazy(() => import('../../pages/site/managementJobRol/')),
        ind:{},
        exact: true,

    },
    {
        path: '/management-soft-skills',
        component: lazy(() => import('../../pages/site/managementSotfSkills/')),
        ind:{},
        exact: true,

    },
    {
        path: '/management-business-skills',
        component: lazy(() => import('../../pages/site/managementBusinessSkills/')),
        ind:{},
        exact: true,

    },
    {
        path: '/companies',
        component: lazy(() => import('../../pages/site/companies/')),
        ind:{},
        exact: true,

    },
    {
        path: '/recruiters',
        component: lazy(() => import('../../pages/site/recruiters/')),
        ind:{},
        exact: true,

    },
    {
        path: '/admin',
        component: lazy(() => import('../../pages/site/admin/')),
        ind:{},
        exact: true,

    },
    {
        path: '/registrations',
        component: lazy(() => import('../../pages/site/registrations/')),
        ind:{},
        exact: true,

    },
    {
        path: '/profile',
        component: lazy(() => import('../../pages/site/profile/')),
        ind:{},
        exact: true,

    },
    {
        path: '/jobs',
        component: lazy(() => import('../../pages/site/jobs/')),
        ind:{},
        exact: true,

    }

]

export default  Siteroutes;

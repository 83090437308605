import React, {useState, createContext,} from "react";

const Context=createContext();

const ThemeProviderWrapper = ({children}) => {
    const [showAddtransactionButton, setShowAddtransactionButton] = useState(false)

    return (
        <Context.Provider value={{
            showAddtransactionButton,
            setShowAddtransactionButton,
        }}>
            {children}
        </Context.Provider>
    )

}
export {ThemeProviderWrapper, Context as ThemeContext}

import React, {useContext} from "react";
import { Route, Routes as AppRoutes } from 'react-router-dom'
import {Routes as SiteRoutes, Auth} from "./routes/";
import "../assets/style/loader.css"
import {E404} from "../pages/error";
import {SiteLayout} from "../layout";
import {SiteLoader} from "../components/elements/loaders";
import {AuthContext} from "../context/authContext";

const Routers=()=>{
    const {isAuth}=useContext(AuthContext)
    return (

                !isAuth
                    ?
                    <AppRoutes>
                    {
                        Auth.map((item, index) => {
                            const Component = item.component
                            return (
                                <Route path={item.path} index={item.index} {...item.ind} element={
                                    <React.Suspense  fallback={<SiteLoader/>}>
                                        {<Component/>}
                                    </React.Suspense>
                                } key={index}/>
                            )
                        })
                    }
                            <Route path="*" element={<E404/>}/>
                </AppRoutes>

                    :
                <AppRoutes>
                    <Route path="/" element={<SiteLayout/>}>
                {
                    SiteRoutes.map((item, index) => {
                        const Component = item.component
                        return (
                            <Route path={item.path} index={item.index} {...item.ind} element={
                                <React.Suspense fallback={<div>...</div>}>
                                    {<Component/>}
                                </React.Suspense>
                            } key={index}/>
                        )
                    })
                }
            </Route>
                <Route path="*" element={<E404/>}/>
                </AppRoutes>


    )
}
export default Routers;

import React from "react";
import Erreur404 from "./erreur404";
import Erreur500 from "./Erreur500";

export const E404 = () =>{
    return(
        <Erreur404/>
    )
}
export const E500 = () =>{
    return(
        <Erreur500/>
    )
}
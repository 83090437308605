import React, {createContext, useState} from "react";
import { v4 as uuidv4 } from 'uuid';

const Context=createContext();

const PopUpWrapper = ({children}) => {
    /**
     * this is an example format of a pop-up message
     * @type {{delay: number, id: string, type: string, message: string, group: string}}
     */
    // const popup = {
    //     type: "INFORMATION", // ERROR / INFORMATION
    //     message: 'example message', // popup message
    //     id: 'some id', // pop up identifier, in this case a uuid
    //     group: 'group 1', // pop up group, optional
    //     delay: 19 // pop up display timeout in seconds
    // }


    const addPopup = (newPopup, existingPopups) => {
        if (existingPopups === undefined) {
            existingPopups = []
        }
        const index = existingPopups.findIndex(popup => popup.id === newPopup.id)
        if (index !== -1) {
            existingPopups[index] = newPopup
        } else {
            existingPopups.push(newPopup)
        }
        return existingPopups
    }

    const removePopup = (newPopup, existingPopups) => {
        if (existingPopups === undefined) {
            return []
        }
        const index = existingPopups.findIndex(popup => popup.id === newPopup.id)
        if (index !== -1) {
            existingPopups.splice(index, 1)
        }
        return existingPopups
    }

    /**
     * this is intended to manage pop ups, grouped into errors and notifications
     */
    const [popUps, setPopUps] = useState({errors:[], information:[]})

    const showErrorPopUp = (message) => {
        let error = {
            type: 'ERROR', // ERROR
            message: message, // popup message
            id:  uuidv4(), // pop up identifier
            group: '', // pop up group, optional
            delay: 5 // pop up display timeout
        }
        setPopUps({...popUps,
            errors: addPopup(error, [...popUps.errors])
        })

        // remove pop up from state after delay
        setTimeout(() => {
            setPopUps({...popUps,
                errors: removePopup(error, [...popUps.errors])
            })
        }, error.delay * 1000);
    }

    const showInformationPopUp = (message) => {
        let info = {
            type: 'INFORMATION', // INFORMATION
            message: message, // popup message
            id:  uuidv4(), // pop up identifier
            group: '', // pop up group, optional
            delay: 5 // pop up display timeout
        }
        setPopUps({...popUps,
            information: addPopup(info, [...popUps.information])
        })

        // remove pop up from state after delay
        setTimeout(() => {
            setPopUps({...popUps,
                information: removePopup(info, [...popUps.information])
            })
        }, info.delay * 1000);
    }


    const closePopUp = (popUp) => {
        console.log(popUp.type,' closing popup ',popUp.message)
        setPopUps({...popUps,
                information: removePopup(popUp, [...popUps.information]),
                errors: removePopup(popUp, [...popUps.errors])
            })
    }


    const resolveAndPopUpApiCallError = (err) => {
        err && err.response?.status && err.response?.status === 403 ? showErrorPopUp("access denied, please contact your admin")
            : err && (err.response?.status && err.response?.status !== 403) && (err.response?.data?.message) ? showErrorPopUp(err.response?.data?.message)
            : showErrorPopUp("error on processing request")
    }

    return (
        <Context.Provider value={{
            popUps,
            closePopUp,
            showErrorPopUp,
            showInformationPopUp,
            resolveAndPopUpApiCallError
        }}>
            {children}
        </Context.Provider>
    )
}
export {PopUpWrapper, Context as PopupContext}

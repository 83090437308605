import {lazy} from 'react'

const AuthRoutes = [
    {
        ind:{
            index:true,
        },
        component: lazy(() => import('../../pages/auth/login/')),
        exact: true,

    },
    {
        path: '/login',
        exact: true,
        ind:{},
        component: lazy(() => import('../../pages/auth/login/'))
    },


]

export default  AuthRoutes;
